<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>{{ Object.entries(this.data).length === 0 ? "Assign New" : "Edit" }} Subscription Package </h3>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">
        <div class="vx-col w-full mt-5">
          <label class="block mb-2">Director Name</label>
          <v-select :options="directorOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="directorFilter" class="mb-4 md:mb-0" />
        </div>
        <div class="vx-col w-full mt-5">
          <label class="block mb-2">Package Name</label>
          <v-select :options="packageOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="packageFilter" class="mb-4 md:mb-0" />
        </div>
        <div class="vx-col w-full mt-8">
<!--          <label class="text-sm">Subscription Starting Date</label>-->
          <datepicker
            format="MMMM dd yyyy"
            :disabled-dates="disabledDates"
            minimum-view="month"
            v-model="startDate"
            placeholder="Subscription Start Date"
            prepend-icon="event"
            :input-class="{
                'is-true':
                  !errors.first('startDate') &&
                  startDate,
                'is-danger': errors.first('startDate')
              }"
          >
          </datepicker>
          <span class="text-danger text-sm">{{
              errors.first("startDate")
            }}</span>
          <input
            type="hidden"
            data-vv-as="Start Date"
            data-vv-validate-on="change"
            name="startDate"
            v-validate="'required'"
            v-model="startDate"
          />
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button class="vs-button-secondary" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import vSelect from 'vue-select'
  import {mapActions} from "vuex";
  import Datepicker from "vuejs-datepicker";
  import moment from "moment";

  export default {
    data() {
      return {
        dataId: null,
        directorId: "",
        settings: { // perfect scrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
        packageOptions: [],
        packageFilter: {label: 'All', value: 'all'},
        directorOptions: [],
        directorFilter: {label: 'All', value: 'all'},
        startDate: "",
        disabledDates: { to: new Date() },
      }
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      },
    },
    components: {
      Datepicker,
      vSelect,
      VuePerfectScrollbar,
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {
          this.dataId = this.data._id
          this.initValues()
        }
      },
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
          && this.directorFilter.value != "all"
          && this.packageFilter.value != "all"
          && this.startDate
      }
    },
    methods: {
      ...mapActions("center", ["getDirectorList"]),
      ...mapActions("userSubscription", [
        "assignPackage",
        "getPackageList"
      ]),
      moment(date){
        return moment(date)
      },
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: color
        })
      },
      initValues() {
        if (this.data._id) return
        this.dataId = null
        this.startDate = ""
        this.packageFilter = {label: 'All', value: 'all'};
        this.directorFilter = {label: 'All', value: 'all'};
      },
      fetchDirectorList() {
        this.getDirectorList()
          .then((res) => {
            let data = res.data.data;
            this.directorOptions = data.map((item) => {
              return {
                label: item.fullName,
                value:item._id
              }
            })
          })
      },
      fetchPackageList() {
        this.getPackageList()
          .then((res) => {
            let data = res.data.data;
            this.packageOptions = data.map((item) => {
              return {
                label: item.name,
                value:item._id
              }
            })
          })
      },
      async submitData() {
        await this.$validator.validateAll().then(result => {
          if (result) {
            let data =
              {
                startDate: this.startDate,
                subscriptionPackageId: this.packageFilter.value,
                learningCenterDirectorId: this.directorFilter.value
              };
            this.customError = "";
            if (this.dataId !== null) {
              this.$vs.loading()
            } else {
              this.$vs.loading()
              this.assignPackage(data)
                .then((res) => {
                  if (res.status === 200) {
                    this.$vs.loading.close()
                    this.$emit('refreshData', true)
                    this.showMessage("Success", "Subscription package assigned successfully.", "success");
                  }
                })
                .catch(err => {
                  this.$vs.loading.close()
                  this.showMessage("Failed","Failed to assign subscription package", "danger");
                  // console.error(err)
                })
            }
            this.$emit('closeSidebar')
          }
        })
        // this.initValues()
      },
    },
    created(){
      this.fetchDirectorList();
      this.fetchPackageList();
    }
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }
</style>
