<template>
  <div>
    <SubscriptionSidebar
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      @refreshData="refreshData"
      :data="sidebarData"
    />
    <vx-card>
      <div class="flex justify-between align-items-center mb-8">
          <h2 class="mb-0">User Subscriptions</h2>
        <vs-button class="mb-base mr-3" @click="assignPackage">Assign Package</vs-button>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col md:w-1/2 w-full" align="left">
          <vs-input class="w-full search-input" v-model="searchQuery" placeholder="Search..."/>
        </div>
      </div>
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :max-items="dataTableParams.limit"
        :data="userSubscriptionData"
        @search="handleSearch"
        @change-page="handleChangePage">
<!--        @sort="handleSort"-->
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between mb-5"
        >
          <vs-select
            label="Sort By"
            placeholder="Sort By"
            autocomplete
            v-model="dataTableParams.sort"
            class="mr-6"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in sortOptions"
              :clearable="false"
            />
          </vs-select>
          <vs-select
            label="Status"
            placeholder="Status"
            autocomplete
            v-model="dataTableParams.status"
            class="mr-6"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in statusOptions"
              :clearable="false"
            />
          </vs-select>
        </div>

        <template slot="thead">
          <vs-th>Customer</vs-th>
          <vs-th>Status</vs-th>
          <vs-th>Package</vs-th>
          <vs-th>Email</vs-th>
          <vs-th>Start Date</vs-th>
          <vs-th>End Date</vs-th>
          <vs-th>Package Type</vs-th>
          <vs-th class="justify-center">Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.companyName">
              {{ tr.companyName | capitalize }}
            </vs-td>
            <vs-td :data="tr.endDate">
              {{ tr.endDate ? (moment(tr.endDate) >= moment() ? "Active" : "Expired") : 'Active'  }}
            </vs-td>
            <vs-td :data="tr.type">
              {{ tr.name | capitalize}}
            </vs-td>
            <vs-td :data="tr.email">
              {{ tr.email }}
            </vs-td>
            <vs-td :data="tr.startDate">
              {{ moment(tr.startDate).format('MMMM DD YYYY') }}
            </vs-td>
            <vs-td :data="tr.endDate">
              {{ tr.endDate ? moment(tr.endDate).format('MMMM DD YYYY') : '' }}
            </vs-td>
            <vs-td :data="tr.price">
              ${{ tr.price }}/per child
            </vs-td>
            <vs-td :data="tr._id" class="text-center">
              <vs-dropdown vs-trigger-click closable class="cursor-pointer">
                <feather-icon icon="MoreVerticalIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" />
                <vs-dropdown-menu class="vx-navbar-dropdown">
                  <ul style="min-width: 15rem">
                    <li
                      class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                      @click="editDetailHandler(tr._id)">
                      <span class="ml-2">Edit Subscription</span>
                    </li>
                    <li
                      class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                      @click="viewPastSubscriptionHandler(tr.userId)">
                      <span class="ml-2">View Past Subscription</span>
                    </li>
                    <li
                      class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                      @click="viewDetailHandler(tr._id)">
                      <span class="ml-2">View Customer Details</span>
                    </li>
                  </ul>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2" v-if="userSubscriptionData.length > 0">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import vSelect from "vue-select";
  import moment from "moment";
  import SubscriptionSidebar from "./assignPackageSidebar";

  export default {
    components: {
      "v-select": vSelect,
      SubscriptionSidebar
    },
    data() {
      return {
        isMounted: false,
        totalDocs: 0,
        dataTableParams: {
          search: "",
          sort: "startDate",
          dir: "desc",
          limit: 50,
          page: 1,
          status: ""
        },
        userSubscriptionData: [],
        statusOptions: [
          { text: 'All', value: "" },
          { text: 'Active', value: "Active" },
          { text: 'Expired', value: "Expired" },
         ],
        sortOptions: [
          { text: 'Start Date', value: "startDate" },
          { text: 'End Date', value: "endDate" },
          { text: 'Package Type', value: "type" },
        ],
        serverResponded: false,
        searchQuery:"",
        addNewDataSidebar: false,
        sidebarData: {},
      }
    },
    methods: {
      ...mapActions("userSubscription", ["getUserSubscriptionsList"]),
      moment(date){
        return moment(date)
      },
      async getUserSubscriptionsData() {
        this.dataTableParams.search = this.searchQuery;
        await this.getUserSubscriptionsList(this.dataTableParams).then(res => {
          this.userSubscriptionData = res.data.data.docs;
          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
          this.serverResponded = true;
        })
      },
      handleSearch(searching) {
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getUserSubscriptionsData();
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.getUserSubscriptionsData();
      },
      handleSort(key, active) {
        this.dataTableParams.sort = key;
        this.dataTableParams.dir = active;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getUserSubscriptionsData();
      },
      editDetailHandler(id) {
        this.$router.push({name:'user-subscriptions-edit', params: { id: id } })
      },
      viewPastSubscriptionHandler(id) {
        this.$router.push({name:'user-subscriptions-past-subscription', params: { id: id } })
      },
      viewDetailHandler(id) {
        this.$router.push({name:'user-subscriptions-view', params: { id: id } })
      },
      assignPackage() {
        this.sidebarData = {}
        this.toggleDataSidebar(true)
      },
      toggleDataSidebar(val = false) {
        this.addNewDataSidebar = val
      },
      refreshData() {
        setTimeout(() => {
          this.getUserSubscriptionsData();
        }, 2000); // 2 sec delay

      }
    },
    computed: {
      totalPage: function() {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      },
    },
    watch: {
      "dataTableParams.page": function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.page = newVal;
          this.getUserSubscriptionsData();
        }
      },
      "dataTableParams.limit": function(newlimit, oldLimit) {
        if (newlimit !== oldLimit) {
          this.dataTableParams.page = 1;
          this.dataTableParams.limit = newlimit;
          this.getUserSubscriptionsData();
        }
      },
      "dataTableParams.sort": function(newSort, oldSort) {
        if (newSort !== oldSort) {
          this.dataTableParams.page = 1;
          this.dataTableParams.sort = newSort;
          this.getUserSubscriptionsData();
        }
      },
      'dataTableParams.status': function(newStatus, oldStatus) {
        if(newStatus !== oldStatus){
          this.dataTableParams.status = newStatus;
          this.getUserSubscriptionsData();
        }
      },
      searchQuery: function (val) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getUserSubscriptionsData();
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        this.awaitingSearch = true;
      },
    },
    created() {
      this.getUserSubscriptionsData();
    }
  }

</script>
<style lang="scss">
  .vs-dropdown--menu {
    ul {
      li {
        position: relative;
        z-index: 11;
      }
    }
  }
</style>
